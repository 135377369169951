import { SimpleStore } from 'services/util/SimpleStore';
import get from 'lodash.get';
import { SummaryOperation, SummaryStat } from 'types/Dashboards';

export function calculateStats(
    storeData: SimpleStore[],
    fieldSelection: string,
    statSelection: SummaryStat,
    operation: SummaryOperation,
    prevCalc: number,
): number | undefined {
    const data = keyValSearch(storeData, fieldSelection);
    let calculation: number;

    if (!data.length) {
        return undefined;
    }

    calculation = data.reduce((total, current) => {
        switch (statSelection) {
        case 'Sum':
            if (current !== undefined && current !== null) {
                return Number(total) + Number(current);
            }
            return total;
        case 'Average':
            if (current !== undefined && current !== null) {
                return Math.round((total + current / data.length) * 10) / 10;
            }
            return total;
        case 'Minimum':
            if (current !== undefined && current !== null) {
                return total === 0 ? current : Math.min(total, current);
            }
            return total;
        case 'Maximum':
            if (current !== undefined && current !== null) {
                return total === 0 ? current : Math.max(total, current);
            }
            return total;
        case 'Count':
            return data.length;
        default:
            return total;
        }
    }, 0);

    if (calculation !== undefined && calculation !== null && !isNaN(calculation)) {
        if (operation && prevCalc !== undefined && prevCalc !== null) {
            calculation = applyOperation(calculation, operation, prevCalc);
        }
        return calculation;
    }

    function applyOperation(calculation: number, operation: SummaryOperation, prevCalc: number): number {
        switch (operation) {
        case 'Add':
            return prevCalc + calculation;
        case 'Subtract':
            return prevCalc - calculation;
        case 'Multiply':
            return prevCalc * calculation;
        case 'Divide':
            return prevCalc / calculation;
        default:
            return calculation;
        }
    }
}

export function keyValSearch(data: Record<string, any>[], key: string, results: Array<any> = []): Array<any> {
    const res = results;
    Object.keys(data).forEach((obj) => {
        const val = get(data[obj], key);
        if (val !== undefined && val !== null) {
            res.push(val);
        }
    });
    return res;
}
