/* eslint-disable no-console */
import { Aurelia } from 'aurelia-framework';
import * as environment from '../config/environment.json';
import { PLATFORM } from 'aurelia-pal';
import { setupLogging } from 'logging';
import { AppConfig } from './services/util/AppConfig';
import { createApp } from './services/api/feathers';
import { createFileService, FileService } from './services/api/file';
import { FeathersApplication } from './types/Application';
import { DB } from './services/util/DB';

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then((registration) => {
            console.log('SW registered: ', registration);
        }).catch((registrationError) => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

export async function configure(aurelia: Aurelia): Promise<void> {

    aurelia.use
        .standardConfiguration()
        .feature(PLATFORM.moduleName('resources/index'))

        // misc
        .plugin(PLATFORM.moduleName('aurelia-gridstack'))
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .plugin(PLATFORM.moduleName('aurelia-dialog'), (config) => {
            config.useDefaults();
            config.useCSS('');
        })

        // ux
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/all'));

    if (environment.testing) {
        aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    // di registration
    const config = new AppConfig();
    await config.initialize();
    setupLogging(config);
    aurelia.container.registerInstance(AppConfig, config);
    const app = createApp(config);
    const fileService = createFileService(config.API_HOST, app);
    aurelia.container.registerInstance(FileService, fileService);
    aurelia.container.registerInstance(FeathersApplication, app);
    // aurelia.container.registerTransient(OfflineService, OfflineService);

    const db = new DB();
    await db.connect();
    aurelia.container.registerInstance(DB, db);

    await aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
