import { autoinject, bindable } from 'aurelia-framework';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { EventAggregator } from 'aurelia-event-aggregator';
import { IDashboardItem, IDashboardSummaryConfig, ISummaryStep, SummaryOperation, SummaryStat } from '../../types/Dashboards';
@autoinject
export class SummaryGadgetEditor {
    @bindable editItem: IDashboardItem<IDashboardSummaryConfig>;
    @bindable storeData: SimpleStore;
    statisticOptions: SummaryStat[] = ['Count', 'Average', 'Minimum', 'Maximum', 'Sum'];
    operationOptions: SummaryOperation[] = ['Add', 'Subtract', 'Multiply', 'Divide'];
    @bindable steps: ISummaryStep[];

    constructor(
        private stores: StoreManager,
        private ea: EventAggregator,
    ) { }

    activate(model: any): void {
        if (!model.config.steps) {
            model.config.steps = [{
                step: 1,
                label: 'New Step',
                operation: '',
                fieldSelection: model.config.fieldSelection || undefined,
                statSelection: model.config.statSelection || undefined,
            }] as ISummaryStep[];
        }

        model.config.steps.sort((a, b) => a.step - b.step);
        this.editItem = model;
        this.getStoreData();

    }

    addStep() {
        const step: number = this.editItem.config.steps.length + 1;
        const newStep = {
            step: step,
            label: 'New Step',
        } as ISummaryStep;
        this.editItem.config.steps.push(newStep);
    }

    removeStep(stepCount) {
        this.editItem.config.steps = this.editItem.config.steps
            .filter((step) => step.step !== stepCount)
            .map((step, i) => ({ ...step, step: i + 1 }));

        this.updateData('removeStep');
    }

    updateData(event?) {
        if (event === 'store') {
            this.getStoreData();
        }
        this.ea.publish('edit-item', this.editItem);
    }

    getStoreData() {
        const prevSurveyId = this.storeData?.defaultQuery.survey_id;
        const prevSteps = this.editItem.config.steps.map((s) => s.fieldSelection);
        this.storeData = this.stores.getStore(this.editItem.config.storeId) as SimpleStore;

        this.storeData?.refresh()
            .then((result) => {
                this.storeData = result;
                if (prevSurveyId && result.defaultQuery.survey_id === prevSurveyId) {
                    this.editItem.config.steps.forEach((step, i) => step.fieldSelection = prevSteps[i]);
                }
            });
    }

}
